export const SANITIZE_TEXT_REGEX = /[^a-zA-Z<>&@.-\d\s:]/g
export const SANITIZE_NUMBER_REGEX = /[^0-9]/g
export const PDP_REGEX = /^([\/]).*.([\-]).([0-9]{2,}).*$/gm
export const MEMBERSHIP_VALID_MAIL_ERROR_REGEX = /\S+@\S+\.\S+/
export const MEMBERSHIP_CODE_ERROR_REGEX = /@kohler.com\s*$/
export const FULL_NAME_REGEX = /^[a-zA-Z]+ [a-zA-Z]+$/
export const PURE_TEXT_REGEX = /<[^>]*>/g
export const SPECIAL_CHAR_REGEX = /[^a-zA-Zd0-9 ]/g
export const NUMBER_HEADING_REGEX = /^[1-6]$/
export const ALPHABET_WITH_SPACE_REGEX = /^[a-zA-Z ]*$/
export const POSTAL_CODE_US_REGEX = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/
export const POSTAL_CODE_CA_REGEX = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/
export const POSTAL_CODE_REGEX = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/
export const INCH_SYMBOL_REGEX = /\d$/
export const DATA_LAYER_REGEX = /&amp;/g
export const PURE_DATA_FOR_STRING_REGEX = /(\r\n|\n|\r)/gm
export const SPACE_REGEX = /\s+/g
export const FILTER_PARAM_REGEX = /[\/\\]/g
export const TEXT_AREA_LABEL_REGEX = /\*/g
export const SAVE_TO_LIST_NAME_FIELD_REGEX = /^[a-zA-Z0-9 ]*$/
